export const BLACK_SHEET = [
  "__typename",
  "isActive",
  "publishers",
  "rights",
  "price",
  "order",
  "slug",
  "uuid",
  "level",
  "offset",
  "part",
  "text",
  "path",
  "bbk",
  "isbn",
  "href",
];

export const FIELD_NAMES = {
  bbk: "BBK",
  epub: "EPUB",
  isbn: "ISBN",
  name: "Название",
  tags: "Теги",
  tag: "Тег",
  image: "Обложка",
  authors: "Авторы",
  firstName: "Имя",
  lastName: "Фамилия",
  patronymic: "Отчество",
  annotation: "Аннотация",
  birthday: "Дата рождения",
  deathday: "Дата смерти",
  ageLimit: "Возрастные ограничения",
  deepLink: "Короткая ссылка",
  isGlobalVersion: "Глобальная версия",
  categories: "Категории",
  category: "Категория",
  audioBooks: "Аудиокниги",
  publicationDate: "Начало публикации",
  publicationEndDate: "Окончание публикации",
  file: "Файл",
  title: "Название",
  material_subject_programs_attributes: "Атрибуты материалов",
  theme_frame_ids: "Идентификаторы тематических каркасов",

  primary: "Основное",
  class_level_ids: "Параллель класса",
  subject_program_id: "Идентификатор программы",
  didactic_unit_ids: "Дидактическая единица",
};
