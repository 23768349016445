import { useState } from "react";
import { Button, Modal, Spin, Tooltip, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  useSyncFindOneQuery,
  useSyncUpdateMutation,
} from "../../../../graphql/generated/graphql_neb_sync";
import BookMarking from "../../BookMarking/ui/ui";

const { Title } = Typography;

const BookMarkingEditModal = (props) => {
  const { visible, syncId, onCancel } = props;

  const {
    loading,
    error,
    data: book,
  } = useSyncFindOneQuery({
    variables: { syncFindOneId: syncId },
    fetchPolicy: "network-only",
  });

  const [syncUpdate, { loading: mutationLoading }] = useSyncUpdateMutation();

  const handleChangeSync = ({ dataBook, annotation }) => {
    const { material_subject_programs_attributes, theme_frame_ids, sections } =
      dataBook;

    const removeEmptyDidacticUnitIds = sections.map((obj) => {
      if (obj.didactic_unit_ids === "") {
        const { didactic_unit_ids, ...rest } = obj;
        return rest;
      }
      return obj;
    });

    const _book = JSON.parse(book?.syncFindOne.dataBook);

    const _dataBook = JSON.stringify({
      ..._book,
      sections: removeEmptyDidacticUnitIds,
      material_subject_programs_attributes,
      theme_frame_ids,
    });

    syncUpdate({
      variables: {
        syncUpdateId: syncId,
        body: {
          bookUuid: book?.syncFindOne.bookUuid,
          annotation,
          dataBook: _dataBook,
        },
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            syncFindAll: (currentValue = []) => {
              return currentValue.map((item) => {
                if (item.id === syncId) {
                  return data?.syncUpdate;
                }
                return item;
              });
            },
          },
        });
      },
    }).then(() => {
      onCancel();
    });
  };

  return (
    <Modal
      width={"90%"}
      title={false}
      open={visible}
      maskClosable={false}
      onCancel={onCancel}
      footer={false}
    >
      {loading ? (
        <div>loading</div>
      ) : error ? (
        <div>error</div>
      ) : (
        <div>
          <Spin spinning={mutationLoading}>
            <Title level={4}>Редактировать разметку</Title>
            <BookMarking
              annotation={book?.syncFindOne.annotation || ""}
              bookData={JSON.parse(book?.syncFindOne.dataBook)}
              onChange={(sections) => handleChangeSync(sections)}
            />
          </Spin>
        </div>
      )}
    </Modal>
  );
};

const BookMarkingEdit = (props) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Tooltip placement="bottomRight" title="Редактировать разметку в МЭШ">
        <Button type="dashed" icon={<EditOutlined />} onClick={showModal} />
      </Tooltip>
      {visible && (
        <BookMarkingEditModal
          {...props}
          visible={visible}
          onCancel={hideModal}
        />
      )}
    </>
  );
};

export default BookMarkingEdit;
