import React, { useState } from "react";
import { Button, Form, Input, Modal, Switch, Tooltip } from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";

import {
  AdditionalMarkupModalFormProps,
  AdditionalMarkupProps,
  CreateAdditionalMarkupProps,
} from "../types";

const AdditionalMarkupModalForm = (props: AdditionalMarkupModalFormProps) => {
  const { visible, values, onCancel, onChange } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      width={860}
      title={values ? "Редактировать атрибут" : "Создать атрибут"}
      open={visible}
      maskClosable={false}
      okText="Сохранить"
      cancelText="Отмена"
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then((valuesForm) => {
          form.resetFields();
          onChange(valuesForm);
        });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="AdditionalMarkupModalForm"
        initialValues={{ primary: false, ...values }}
      >
        <Form.Item
          name="subject_program_id"
          label="Идентификатор программы"
          rules={[{ required: true, message: "Заполните поле" }]}
        >
          <Input
            onKeyDown={(event) => {
              const isValidKey =
                /^[0-9]+$|^(Backspace|Delete|ArrowLeft|ArrowRight)$/i.test(
                  event.key
                );
              if (!isValidKey) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="class_level_ids"
          label="Параллель класса"
          rules={[{ required: true, message: "Заполните поле" }]}
        >
          <Input
            onKeyDown={(event) => {
              const isValidKey =
                /^[0-9,]+$|^(Backspace|Delete|ArrowLeft|ArrowRight)$/i.test(
                  event.key
                );
              if (!isValidKey) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item name="primary" label="Основное (?)" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AdditionalMarkupForm = (props: CreateAdditionalMarkupProps) => {
  const { onChange, values } = props;
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleChange = (valuesForm: AdditionalMarkupProps) => {
    setVisible(false);
    onChange(valuesForm);
  };

  return (
    <>
      {values ? (
        <Tooltip title="Редактировать атрибут">
          <Button type="text" icon={<EditOutlined />} onClick={showModal} />
        </Tooltip>
      ) : (
        <Button type="link" onClick={showModal} icon={<PlusCircleOutlined />}>
          Добавить атрибут
        </Button>
      )}

      {visible && (
        <AdditionalMarkupModalForm
          visible={visible}
          values={values}
          onCancel={hideModal}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default AdditionalMarkupForm;
