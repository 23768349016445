import { Divider, Empty, Typography } from "antd";
import { BLACK_SHEET, FIELD_NAMES } from "../../../../lib/const";
import cls from "./BookDetail.module.scss";

const { Title } = Typography;

export const BookDetail = ({ bookData, children }) => {
  const hasBookData = bookData && Object.keys(bookData).length > 0;

  function renderTree(obj) {
    const treeData = [];

    Object.entries(obj).forEach(([prop, value]) => {
      if (!BLACK_SHEET.includes(prop)) {
        let displayValue;

        switch (true) {
          case typeof value === "object" && value !== null:
            displayValue = renderTree(value);
            break;
          case typeof value === "string":
            displayValue = `${value}`;
            break;
          case value === null:
            displayValue = "-";
            break;
          default:
            displayValue = `${value}`;
        }

        treeData.push(
          <div key={prop} className={cls.content}>
            {isNaN(prop) && (
              <strong>
                {prop in FIELD_NAMES ? FIELD_NAMES[prop] : prop}:{" "}
              </strong>
            )}
            {displayValue}
          </div>
        );
      }
    });

    return treeData;
  }

  return hasBookData ? (
    <div className={cls.bookDetail}>
      <Title level={4}>{bookData?.name}</Title>
      <Divider />
      {renderTree(bookData)}
      <div className={cls.footer}>{children}</div>
    </div>
  ) : (
    <Empty description="Информация отсутствует" />
  );
};
