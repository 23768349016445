import {
  Alert,
  Badge,
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Tree,
  Typography,
  message,
} from "antd";

import { DeleteOutlined } from "@ant-design/icons";

import cls from "./style.module.scss";
import { transformErrors } from "../../../../lib/utils";
import { array, object, string } from "yup";
import AdditionalMarkupForm from "../../AdditionalInformationMarkup/ui/AdditionalMarkupForm";
import Column from "antd/lib/table/Column";
import useTreeData from "../../../../hooks/useTreeData";
import useAdditionalMarkup from "../../../../hooks/useAdditionalMarkup";
import TextArea from "antd/es/input/TextArea";

const { Title, Text } = Typography;

const schema = object().shape({
  material_subject_programs_attributes: array()
    .required()
    .min(1, "Добавте хотя бы один атрибут в раздел Дополнительная информация"),
  theme_frame_ids: string().required("Заполните поле"),
});

const BookMarking = ({ bookData, annotation, onChange }) => {
  const { treeData, renderTreeNodes, sectionsWithGoals } = useTreeData(
    bookData.sections
  );

  const {
    formValues,
    formErrors,
    handleRemoveItem,
    handleCreateItem,
    handleUpdateItem,
    handleChangeInput,
    setFormErrors,
  } = useAdditionalMarkup({
    material_subject_programs_attributes:
      bookData.material_subject_programs_attributes,
    theme_frame_ids: bookData.theme_frame_ids,
    annotation: annotation || "",
  });

  const onSendToMesh = async () => {
    for (let element of sectionsWithGoals) {
      if (element.didactic_unit_ids && element.level !== 0) {
        const lastIndex = element.path.lastIndexOf("@");
        const pathParent = element.path.slice(0, lastIndex);

        const parentWithEmptyDidacticUnit = sectionsWithGoals.find(
          (item) => !item.didactic_unit_ids && pathParent === item.path
        );

        if (parentWithEmptyDidacticUnit) {
          return message.error(
            `Дидактическая единица в поле ${parentWithEmptyDidacticUnit.title} не может быть пустой`
          );
        }
      }
    }

    const levelZeroElements = sectionsWithGoals.filter(
      (item) => item.level === 0
    );
    if (levelZeroElements.length === 1) {
      if (levelZeroElements[0].didactic_unit_ids === undefined) {
        return message.error("Проставьте дидактические единицы");
      }
    } else if (
      levelZeroElements.length > 1 &&
      !levelZeroElements.some((item) => item.didactic_unit_ids)
    ) {
      return message.error("Проставьте дидактические единицы");
    }

    try {
      const validData = await schema.validate(formValues, {
        abortEarly: false,
      });

      onChange({
        dataBook: {
          sections: sectionsWithGoals,
          theme_frame_ids: validData.theme_frame_ids,
          material_subject_programs_attributes:
            validData.material_subject_programs_attributes.map((item) => ({
              primary: item.primary,
              class_level_ids: item.class_level_ids,
              subject_program_id: item.subject_program_id,
            })),
        },
        annotation: formValues.annotation ? formValues.annotation : null,
      });
    } catch (error) {
      const errors = transformErrors(error);
      setFormErrors(errors);
      message.error("Заполните раздел 'Дополнительная информация' ");
    }
  };

  return (
    <>
      <SectionHeader badgeCount={1} title="Разметка дидактических полей" />
      {treeData && (
        <Tree defaultExpandAll style={{ marginBottom: 40 }}>
          {renderTreeNodes(treeData)}
        </Tree>
      )}

      <SectionHeader badgeCount={2} title="Дополнительная информация" />
      <Col span={12} style={{ marginBottom: 40 }}>
        <Title level={5}>Идентификаторы тематических каркасов</Title>
        <Input
          status={formErrors?.theme_frame_ids ? "error" : ""}
          value={formValues.theme_frame_ids}
          name="theme_frame_ids"
          onChange={handleChangeInput}
          onKeyDown={(event) => {
            const isValidKey =
              /^[0-9,]+$|^(Backspace|Delete|ArrowLeft|ArrowRight)$/i.test(
                event.key
              );
            if (!isValidKey) {
              event.preventDefault();
            }
          }}
        />
        {formErrors?.theme_frame_ids && (
          <Text type="danger">{formErrors?.theme_frame_ids}</Text>
        )}
      </Col>
      <Col span={24}>
        <Row justify="space-between">
          <Title level={5}>Дополнительная информация</Title>
          <AdditionalMarkupForm onChange={handleCreateItem} />
        </Row>

        {!!formValues.material_subject_programs_attributes.length && (
          <Table
            rowKey={(record) => record.id}
            pagination={false}
            dataSource={formValues.material_subject_programs_attributes}
          >
            <Column title="№" width={80} render={(_, __, index) => index + 1} />

            <Column
              title="Идентификатор программы"
              dataIndex="subject_program_id"
            />

            <Column title="Параллель класса" dataIndex="class_level_ids" />

            <Column
              title="Основное (?)"
              render={(_, record) => (record.primary ? "Основное" : "")}
            />

            <Column
              dataIndex="action"
              width={120}
              align="right"
              render={(_, record) => (
                <Space>
                  <AdditionalMarkupForm
                    onChange={(valuesForm) =>
                      handleUpdateItem(valuesForm, record.id)
                    }
                    values={record}
                  />
                  <Tooltip title="Удалить атрибут">
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => {
                        Modal.confirm({
                          title: "Удалить атрибут?",
                          okText: "Да",
                          cancelText: "Отмена",
                          okType: "danger",
                          onOk: () => handleRemoveItem(record.id),
                        });
                      }}
                    />
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        )}

        {formErrors?.material_subject_programs_attributes && (
          <Alert
            message={formErrors?.material_subject_programs_attributes}
            type="error"
          />
        )}
      </Col>

      <SectionHeader badgeCount={3} title="Аннотация" />
      <Col span={24} style={{ marginBottom: 40 }}>
        <TextArea
          status={formErrors?.annotation ? "error" : ""}
          value={formValues.annotation}
          name="annotation"
          rows={8}
          onChange={handleChangeInput}
        />
        {formErrors?.annotation && (
          <Text type="danger">{formErrors?.annotation}</Text>
        )}
      </Col>

      <Divider />

      <div className={cls.footer}>
        <Tooltip placement="topRight" title="Сохранить и отправить в МЭШ">
          <Button size="large" type="primary" onClick={onSendToMesh}>
            Сохранить и отправить в МЭШ
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

const SectionHeader = ({ badgeCount, title }) => (
  <Divider orientation="left" orientationMargin="0">
    <Badge count={badgeCount} color="geekblue" style={{ marginTop: -4 }} />{" "}
    {title}
  </Divider>
);

export default BookMarking;
