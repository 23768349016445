import { useState } from "react";

const useAdditionalMarkup = (initialValues) => {
  const material_subject_programs_attributes = (
    initialValues.material_subject_programs_attributes || []
  ).map((item, index) => ({
    ...item,
    id: item.id || Date.now() + index,
  }));

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    ...initialValues,
    material_subject_programs_attributes,
  });

  const handleRemoveItem = (id) => {
    setFormValues((oldData) => ({
      ...oldData,
      material_subject_programs_attributes:
        oldData.material_subject_programs_attributes.filter(
          (item) => item.id !== id
        ),
    }));
  };
  const handleCreateItem = (valuesForm) => {
    const id = Date.now();
    setFormValues((oldData) => ({
      ...oldData,
      material_subject_programs_attributes: [
        ...oldData.material_subject_programs_attributes,
        { ...valuesForm, id },
      ],
    }));

    valuesForm.primary && handleMapPrimary(id);
    setFormErrors({ ...formErrors, material_subject_programs_attributes: "" });
  };
  const handleUpdateItem = (valuesForm, id) => {
    setFormValues((oldData) => ({
      ...oldData,
      material_subject_programs_attributes:
        oldData.material_subject_programs_attributes.map((item) =>
          item.id === id ? { ...valuesForm, id } : item
        ),
    }));

    valuesForm.primary && handleMapPrimary(id);
  };
  const handleMapPrimary = (id) => {
    setFormValues((oldData) => ({
      ...oldData,
      material_subject_programs_attributes:
        oldData.material_subject_programs_attributes.map((item) =>
          item.id === id
            ? { ...item, primary: true }
            : { ...item, primary: false }
        ),
    }));
  };
  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  return {
    formValues,
    formErrors,
    handleRemoveItem,
    handleCreateItem,
    handleUpdateItem,
    handleChangeInput,
    setFormErrors,
  };
};

export default useAdditionalMarkup;
