import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { AppRouter } from "./app/router";
import AuthPage from "./page/AuthPage/ui/AuthPage";
import { Button, Layout, Row, Space, Tooltip, theme } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath } from "./app/router/config/routeConfig";

import cls from "./App.module.scss";

const { Header, Content, Footer } = Layout;

function App() {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  const logout = () => {
    keycloak.logout();
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return isLoggedIn ? (
    <Layout className={`${cls.appLayout} site-layout`}>
      <Header>
        <Row justify="space-between" align="middle">
          <Title
            className={cls.title}
            level={3}
            style={{ color: "white" }}
            onClick={() => navigate("/")}
          >
            ЛК МЕТОДИСТА
          </Title>

          <Space>
            <Link to={RoutePath.mesh} className={cls.link}>
              МЭШ
            </Link>
            <Tooltip placement="bottomRight" title="Выйти из ЛК Методиста">
              <Button
                type="dashed"
                icon={<LogoutOutlined />}
                onClick={logout}
              />
            </Tooltip>
          </Space>
        </Row>
      </Header>

      <Content className={cls.content} style={{ padding: "0 50px" }}>
        <div
          className="site-layout-content"
          style={{ background: colorBgContainer }}
        >
          <AppRouter />
        </div>
      </Content>

      <Footer style={{ textAlign: "center" }}>
        ЛК Методиста ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  ) : (
    <AuthPage />
  );
}

export default App;
