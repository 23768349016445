import { Input, Space, Tree } from "antd";
import React, { useEffect, useState } from "react";
import { DownSquareTwoTone } from "@ant-design/icons";

const { TreeNode } = Tree;
const useTreeData = (sections) => {
  const [treeData, setTreeData] = useState([]);
  const [tempTreeData, setTempTreeData] = useState([]);
  const [sectionsWithGoals, setSectionsWithGoals] = useState(
    JSON.parse(JSON.stringify(sections))
  );

  const arrayToTree = (arr, parent = null) =>
    arr
      .filter((item) => item._parent === parent)
      .map((child) => ({
        ...child,
        children: arrayToTree(arr, child.path),
      }));
  const setValues = (value, data) => {
    data.forEach((item) => {
      item.value = value;

      if (value) addInputValueToSections({ uuid: item.uuid, value });

      if (item.children) {
        setValues(value, item.children);
      }
    });
  };
  const addInputValueToSections = ({ uuid, value }) => {
    setSectionsWithGoals((values) =>
      values.map((section) => {
        return uuid === section.uuid
          ? {
              ...section,
              didactic_unit_ids: value,
            }
          : section;
      })
    );
  };
  const changeChildrenInputs = (value, key, data = tempTreeData) => {
    data.forEach((item) => {
      if (item.key === key) {
        setValues(value, item.children);
      }
      if (item.children) {
        changeChildrenInputs(value, key, item.children);
      }
    });

    setTreeData(JSON.parse(JSON.stringify(tempTreeData)));
  };
  const changeInput = (e, key) => {
    addInputValueToSections({ uuid: key, value: e.target.value });
    changeNode(key, e.target.value, tempTreeData);
    setTreeData(JSON.parse(JSON.stringify(tempTreeData)));
  };
  const changeNode = (key, value, data) =>
    data.forEach((item) => {
      if (item.key === key) {
        item.value = value;
        return;
      }

      if (item.children) {
        changeNode(key, value, item.children);
      }
    });
  const renderTreeNodes = (data) => {
    return data.map((item) => {
      item.treeTitle = (
        <Space style={{ padding: 4 }}>
          <span>{item.title}</span>
          <Input
            onKeyDown={(event) => {
              const isValidKey =
                /^[0-9,]+$|^(Backspace|Delete|ArrowLeft|ArrowRight)$/i.test(
                  event.key
                );
              if (!isValidKey) {
                event.preventDefault();
              }
            }}
            size={"small"}
            value={item.value}
            onChange={(e) => changeInput(e, item.key)}
          />
          {item?.children.length ? (
            <DownSquareTwoTone
              onClick={() => changeChildrenInputs(item.value, item.key)}
            />
          ) : null}
        </Space>
      );

      if (item.children) {
        return (
          <TreeNode title={item.treeTitle} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }

      return <TreeNode title={item.treeTitle} key={item.key} />;
    });
  };

  useEffect(() => {
    const newSections = JSON.parse(JSON.stringify(sections));
    newSections?.sort((a, b) => a.order - b.order);

    const treeSections = newSections.map((item) => {
      const _path = "@" + item.path.split("@").pop();
      const a = item.path.replace(_path, "");
      const _parent = item.path === a ? null : a;

      return {
        ...item,
        _parent,
        value: item.didactic_unit_ids || "",
        key: item.uuid,
      };
    });

    setTreeData(() => arrayToTree(treeSections || []));
    setTempTreeData(() => arrayToTree(treeSections || []));
  }, [sections]);

  return {
    treeData,
    renderTreeNodes,
    sectionsWithGoals,
  };
};
export default useTreeData;
